const SEO = [
	{
		page: "home",
		description:
			"I'm Yasin, and I'd like to extend a warm welcome to my portfolio page. This site was made to show my skills (humbly). Having self-studied using Udemy courses on AWS, Terraform, Docker, Linux, and Python, as well as completing a bootcamp, I have applied this knowledge into one comprehensive project which you’re looking at now!",
		keywords: ["Yasin", "Yasin G", "Yasin Gole", "Y Gole", "Gole"],
	},

	{
		page: "about",
		description:
			"I'm Yasin, and I'd like to extend a warm welcome to my portfolio page. This site was made to show my skills (humbly). Having self-studied using Udemy courses on AWS, Terraform, Docker, Linux, and Python, as well as completing a bootcamp, I have applied this knowledge into one comprehensive project which you’re looking at now!",
		keywords: ["Yasin", "Yasin G", "Yasin Gole", "Y Gole", "Gole"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Yasin", "Yasin G", "Yasin Gole", "Y Gole", "Gole"],
	},

	{
		page: "projects",
		description:
			"Knowledge is best when applied, and I've put this belief into practice through a variety of projects. You can find my portfolio right here, showcasing a mix of tech endeavors I've completed.",
		keywords: ["Yasin", "Yasin G", "Yasin Gole", "Y Gole", "Gole"],
	},

	{
		page: "contact",
		description:
			"Thanks for wanting to get in touch! I'm all ears for your thoughts, questions, and suggestions. If you've got something specific in mind, just shoot me an email at ",
		keywords: ["Yasin", "Yasin G", "Yasin Gole", "Y Gole", "Gole"],
		},
];

export default SEO;
