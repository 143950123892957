// Import React and other necessary components and data
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";
import INFO from "../data/user";
import SEO from "../data/seo";
import "./styles/about.css";

// Define an array of certifications with image URLs and links
const certifications = [
  {
    name: "AWS Cloud Practitioner",
    imageUrl: "aws-cloud-practitioner.png", // Replace with your image URL
    link: "https://www.credly.com/badges/1dc91b35-88d8-43ea-91ed-a1b494f01d32/public_url", // Replace with your certification link
  },
  {
    name: "Terraform (003)",
    imageUrl: "terraform-certification.png", // Replace with your image URL
    link: "https://www.credly.com/badges/0ac01f67-92b8-444e-9695-6e1515044529/public_url", // Replace with your certification link
  },
  // Add more certifications as needed
];

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "about");

  return (
    <React.Fragment>
      <Helmet>
        <title>{`About | ${INFO.main.title}`}</title>
        <meta name="description" content={currentSEO.description} />
        <meta name="keywords" content={currentSEO.keywords.join(", ")} />
      </Helmet>

      <div className="page-content">
        <NavBar active="about" />
        <div className="content-wrapper">
          <div className="about-logo-container">
            <div className="about-logo">
              <Logo width={46} />
            </div>
          </div>

          <div className="about-container">
            <div className="about-main">
              <div className="about-right-side">
                <div className="title about-title">
                  {INFO.about.title}
                </div>

                <div className="subtitle about-subtitle">
                  {INFO.about.description}
                </div>

                {/* Render certifications with images and links */}
                <div className="certifications">
                  {certifications.map((certification, index) => (
                    <div key={index} className="certification">
                      <a
                        href={certification.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={certification.imageUrl}
                          alt={certification.name}
                          className="certification-image"
                        />
                      </a>
                      <span className="certification-name">
                        {certification.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="about-left-side">
                <div className="about-image-container">
                  <div className="about-image-wrapper">
                      <img
                        src="about.jpg" // Change the image source
                        alt="about"
                        className="about-image"
                      />
                  </div>
                </div>

                <div className="about-socials">
                  <Socials />
                </div>
              </div>
            </div>
            <div className="about-socials-mobile">
              <Socials />
            </div>
          </div>
          <div className="page-footer">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
