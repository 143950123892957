const INFO = {
	main: {
		title: "Cloud Resume by Yasin Gole",
		name: "Yasin Gole",
		email: "yasingole98@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/yasingole",
		linkedin: "https://www.linkedin.com/in/yasin-gole",
	},

	homepage: {
		title: "Hey there!",
		description: `
		  I'm Yasin, and I'd like to extend a warm welcome to my portfolio page. This site was made to show my skills (humbly). Having self-studied using Udemy courses on AWS, Terraform, Docker, Linux, and Python, as well as completing a bootcamp, I have applied this knowledge into one comprehensive project which you’re looking at now!
	  
		  To create this site I have used:
		  • AWS services: S3, CloudFront, Route53, ACM, and Lambda
		  • Used Python to generate a script to invalidate CloudFront caches
		  • Built the backend AWS infrastructure using IaC via Terraform
		  • Implemented a CICD Pipeline via GitHub actions to deploy the frontend 
		   (which you’re looking at) and the backend infrastructure (that you can’t see)
		  • Implemented version control using Git

		`,
	  },
	about: {
		title: "About me",
		description: `After completing my Biomedical Sciences degree and working in the world of medical laboratories, it dawned on me that the joy of learning and critical thinking, which were such a big part of my degree, were missing from my daily work. That's when I decided to switch gears and explore the tech field. I was drawn to tech because I could put to use the problem-solving and attention-to-detail skills I had honed during my studies.

		So, I took the plunge into the ever-evolving tech landscape, specifically in Cloud and DevOps engineering. I became quite proficient in AWS and got the hang of fundamental DevOps tools like Terraform and Docker. Along the way, I also became skilled in Linux and Python.

		In my spare time (when not upskilling), I like to keep active by doing boxing and football. I also like photography and travelling (that picture is from Alexandria, Egypt).
		
		My Certifications:

		`
	  },
	  



	projects: [
		{
			title: "Developed Terraform modules to automate AWS infrastructure, deploying Nginx on EC2 instances.",
			description:
				"This is an AWS, Terraform, GitHub Actions, and Git-powered automated infrastructure deployment and CI/CD project.",
			logo: "aws_terraform.png",
			linkText: "View Project",
			link: "https://github.com/yasingole/Dev-Ops-Project",
			logoSizeClass: "custom-logo-size",
		},

		{
			title: "Dockerised application on AWS ECS with Terraform",
			description:
				"This is an AWS and Terraform project, utilising: security groups, ECR, IAM, ECS, VPC in order to deploy a dockerised application.",
			logo: "aws_terraform.png",
			linkText: "View Project",
			link: "https://github.com/yasingole/AWS-Dockerised-Node-js-App-with-Terraform",
		},

		{
			title: "Portfolio Website",
			description:
				"This contains all project files related to the construction of this site.",
			logo: "aws_terraform.png",
			linkText: "View Project",
			link: "https://github.com/yasingole/Portfolio-Website",
		},

		{
			title: "More coming soon...",
			description:
				"",
			logo: "clock.png",
			linkText: "View Project",
			link: "",
		},

	],
};

export default INFO;
