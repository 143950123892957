import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";
import INFO from "../data/user";
import SEO from "../data/seo";
import "./styles/projects.css";

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "projects");

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Projects | ${INFO.main.title}`}</title>
        <meta name="description" content={currentSEO.description} />
        <meta name="keywords" content={currentSEO.keywords.join(", ")} />
      </Helmet>

      <div className="page-content">
        <NavBar active="projects" />
        <div className="content-wrapper">
          <div className="projects-logo-container">
            <div className="projects-logo">
              <Logo width={46} />
            </div>
            <div className="projects-title-and-image">
              <div className="title projects-title">My Projects</div>
              <div className="projects-image-container" aria-hidden="true">
                  <img src="project_image.png" alt="Project Image" />
              </div>
            </div>
          </div>
          <div className="projects-container">
            <div className="subtitle projects-subtitle">
              Knowledge is best when applied, and I've put this belief into
              practice through a variety of projects. You can find my portfolio
              right here, showcasing a mix of tech endeavors I've completed.
              Whether it's coding, cloud, DevOps, or something else entirely,
              feel free to dig into the projects and share your insights.
              Collaboration, fresh ideas, and feedback are always welcome.
            </div>

            <div className="projects-list">
              <AllProjects />
            </div>
          </div>
          <div className="page-footer">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Projects;
